import { Grid } from '@material-ui/core';
import React from 'react';

import Layout from '../Layout';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';
import Separator from '../Separator';
import Subheading from '../Subheading';
import Textblob from '../Textblob';

const Job = ({ position, title, description }) => (
  <>
    <Subheading>{position}</Subheading>
    <Subheading style={{ marginTop: '-2rem' }}>{title}</Subheading>
    <Textblob>{description}</Textblob>
  </>
);

const renderJobs = jobs => (
  <Grid container justify="center" spacing={24}>    
    {false && jobs &&
      jobs.map((job, i) => (
        <React.Fragment key={job.job_title + i}>
          {i > 0 && <Separator height={2} />}
          <Grid item xs={12}>
            <Job
              position={job.position}
              title={job.job_title}
              description={job.job_description}
            />
          </Grid>
        </React.Fragment>
      ))}
  </Grid>
);

export default ({ pageContext: { searchIndex, jobs } }) => {
  return (
      <>
          <Layout background={false}>
              <PageTitle>{jobs.acf.title}</PageTitle>
              <iframe
                  style={{ width: "100%", border: "none", minHeight: "80vh" }}
                  id="personio-iframe"
                  src="https://florafaunavisions-gmbh.jobs.personio.de"
              ></iframe>
              <Narrow>{renderJobs(jobs.acf.job)}</Narrow>
          </Layout>
      </>
  );
};
